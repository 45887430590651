import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import CategoryTitle from "../components/index/categorytitle"
import { Link } from "gatsby"
import AsideItem from "../components/aside/item"

const Kurumsal = () => (
  <Layout>
    <SEO title="corporate" />
    <div id="kurumsal" className="inlinePage footerFixed center-h">
      <div className="container-fluid w80 ma pt5 pb55 col-xs-pt15">
        <CategoryTitle title="SERENAY" subTitle="Corporate" description="Corporate" isDark={false} position="inline" />
        <div className="row">
        	<div className="w20 left pt15 mt15 col-sm-w100 pr45 col-sm-pr0">
        		<aside className="w100 left pos-r">
        			<ul className="w100 left fs14 fw6" style={{letterSpacing: "0.5px"}}>
			        	<AsideItem to="/corporate" title="Our story" icon="icon-serenay" />
			        	<AsideItem to="/ID" title="Corporate ID" icon="icon-kariyer1" />
			        	<AsideItem to="/security" title="Privacy & Security" icon="icon-shield2" />
			        </ul>
        		</aside>
        	</div>
        	<div className="w80 col-sm-w100 left bl1 bc1 col-sm-bl0 col-sm-pl0 pt15 mt15 pl45">
	        	<article className="w100 left fs18">
	        		<p className="colored3">
	        		<b>Serenay Software Ltd. Şti. As </b><b> 2006 </b>, we have been providing corporate web page design, software development solutions and consultancy services to our valued customers. <b> Turkey the four corners of the services we provide </ b> We are proud to provide our customers with valuable solutions. Do you have a project? Take a look at our projecting processes, review our references and success stories. Send us your project details using our customer service and support channels. Maybe the solution you are looking for is here.
	        		</p>
	        		<p>
	        			<b className="w100 left lh38"><i className="icon icon-work mr10 left p10 b-circle colored3-bg white"></i>Project Processes</b> 
	        			<i className="fs20 fw5 colored3 pl45 col-xs-pl0 left">In order to transform your needs into a product in the fastest way possible in project planning processes; We use agile application methods. The main issues we take as basis in the project design phase are as follows;</i>
	        		</p>
	        		<div>
	        			<ul className="w100 left ml25 pl20 col-xs-ml0 pt25 pb25 mt15 mb15 b-ra5 bs3" style={{backgroundColor:"#f7f7f7"}}>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Determining your needs by you and notifying us of your requests</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>On-site inspection of your needs by our expert system analyst team</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Determining the road map according to urgency situations</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Providing infrastructure studies to ensure that the application is at an improvable scale.</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Application development process and preparation of tests</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Testing in the production environment</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Transfer of the application in ready state</li>
	        				<li className="w100 left mb7"><i className="icon icon-right2 fs8 left lh28 mr7"></i>Providing support services</li>

	        			</ul>
	        		</div>
	        		<p>
	        			<Link className="colored2 p15 mt15 wi left b-ra5 fw6" to="/solutions">View Our Solutions <i className="icon icon-right5 fs8"></i></Link>
	        		</p>
	        	</article>
	        </div>
        </div>        
      </div>
    </div>
  </Layout>
)

export default Kurumsal
